@import "../../styles/colors";

.paper {
  max-width: 100%;
  width: fit-content;
  padding: 0 30px 20px 30px;
  box-sizing: border-box;
  min-height: 32px;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.14), 0 6px 30px rgba(0, 0, 0, 0.12),
    0 8px 10px rgba(0, 0, 0, 0.2);
  .drawerTitle {
    display: flex;
    justify-content: space-between;
    height: 52px;
    align-items: center;
    &.draggable {
      cursor: move;
    }

    padding: 0;
    .title {
      font-family: "IBM Plex Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 0.5px;
      color: /*#828282*/ $light-theme-text-2;
    }
    .closeButton {
      display: flex !important;
      position: absolute !important;
      top: 5px;
      right: 8px;
      font-size: 20px !important;
      cursor: pointer !important;
      svg {
        color: $light-icon;
      }
    }
  }
  .titleDivider {
    margin-top: 0;
  }
  .drawerContent {
    padding: 0;
    :global .MuiFormControl-root {
      margin-top: 16px;
    }
  }
  .drawerActions {
    padding: 20px 0 0 0;
    .button {
      font-family: "IBM Plex Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;
      letter-spacing: 1.25px;
      padding: 12px 30px;
      &.dimmed {
        color: $light-theme-text-2;
      }
      &.action {
        color: white;
      }
    }
  }
}

.paper.mobile {
  .drawerTitle {
    .closeButton {
      font-size: 28px !important;
    }
  }
}
