.root {
  width: fit-content;
  padding-left: 11px;

  :global(.MuiSvgIcon-root) {
    font-size: 18px;
  }
  .groupLabel {
    font-size: 12px;
  }
  .optionLabel {
    font-size: 15px;
    font-style: normal;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: normal;
    line-height: 120%;
    letter-spacing: 0.15px;
  }
}
