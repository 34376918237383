@import "~@river/common-ui/src/styles/colors";

.root {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  &.selected {
    background-color: $selected-sidebar !important;
    .icon {
      color: white !important;
    }
  }

  .icon {
    color: $dark-icon !important;
    min-width: auto;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .text {
    margin-left: 8px;
    font-family: "IBM Plex Sans", sans-serif !important;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 120% !important;
    letter-spacing: 0.1px !important;
    color: white;
  }
}
