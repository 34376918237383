@import "~@river/common-ui/src/styles/colors";

.root {
  display: flex;
  padding: 16px 30px;
  .preview {
    width: 86px;
    height: 86px;
    min-width: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.blank {
      border: 1px solid $gray4;
    }
    .logo {
      width: 100%;
      height: 100%;
      background-position: center !important;
      background-size: auto !important;
      &.placeholder {
        background: url("../../../assets/image-upload.svg") no-repeat;
      }
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 30px;
    width: 220px;

    .uploadControl {
      .title {
        font-family: "IBM Plex Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.15px;
        color: $light-theme-text-2;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      input {
        display: none;
      }
    }
    .subtitle {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 0.5px;
      color: $gray4;
    }
  }

  &.mobile {
    padding: 16px 0;
    .info {
      padding-left: 8px;
    }
  }
}
