@import "~@river/common-ui/src/styles/colors";

.root {
  height: 100%;
  .content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .icon {
      width: 16px;
      height: 16px;
    }
    .text {
      margin-left: 8px;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 120%;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      color: $gray2;
    }
    &.active {
      .icon {
        color: $green;
      }
    }
    &.blocked {
      .icon {
        color: $red;
      }
    }
  }
}
