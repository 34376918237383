@import "~@river/common-ui/src/styles/colors";

.root {
  width: 100%;
  height: 100%;
  .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    .icon {
      width: 16px;
      height: 16px;
    }
    .textContainer {
      overflow: hidden;
      flex: 1;
      .text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: 8px;
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.5px;
        color: $gray2;
      }
    }
    &.connected {
      .icon {
        color: $green;
      }
    }
    &.notConnected {
      .icon {
        color: $red;
      }
    }
  }
}
