@import "~@river/common-ui/src/styles/colors";

.root {
  display: flex;
  align-items: center;
  width: 100%;
  .emailContainer {
    flex: 1;
    overflow: hidden;
    .email {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 120%;
      letter-spacing: 0.25px;
      color: $light-theme-text-2;
    }
  }
}
