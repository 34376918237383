@import "~@river/common-ui/src/styles/colors";

.root {
  border-top: 1px solid $light-theme-stroke;
  padding-top: 16px;
  .oldPassword {
    margin-bottom: 16px !important;
  }
  .dialogButtons {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;

    .button {
      font-family: "IBM Plex Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;
      letter-spacing: 1.25px;
      padding: 8px 24px;
      &.cancel {
        color: $light-theme-text-2;
      }
    }
  }
}
