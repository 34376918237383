.paper {
  max-width: 850px;
  width: 100%;
  height: 700px;
  .content {
    .dataGrid {
      flex: 1;
      width: 100%;
      height: 100%;
      :global .rdg-header-row,
      :global .rdg-row {
        grid-template-columns: 2.4fr 3.3fr 4.3fr;
      }
      .clickableDropDownModule {
        svg {
          cursor: pointer;
        }
      }
      .dropDownModule {
        margin: 0;
        display: flex;
        align-items: center;

        svg {
          float: left;
          display: table;
          height: 100%;
          margin-right: 10px;
          > span {
            display: table-cell;
            vertical-align: middle;
            cursor: pointer;
          }
        }
        .dropDownModuleText {
          margin: 0;
        }
      }
      .subDirectoryIcon {
        margin-left: 10px;
      }
      .subMenuGrid {
        padding-left: 40px;
      }
    }
  }
}
