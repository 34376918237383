@import "../../styles/colors";

.content {
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-size: 15px;
  color: $gray2;
  padding: 8px 54px !important;
}

.actionButton {
  text-transform: none !important;
}
