@import "~@river/common-ui/src/styles/colors";

.root {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  padding-right: 16px;
  .image {
    display: flex;
    justify-content: center;
  }
  .text {
  }
}
