@import "~@river/common-ui/src/styles/colors";
.paper {
  width: 1200px;
  max-width: unset;
  .content {
    .addParamsSection {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-top: 1px solid $light-icon;
      border-bottom: 1px solid $light-icon;
      margin-top: 20px;
      margin-bottom: 4px;
      .title {
        padding-top: 20px;
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.5px;
        color: $light-theme-text-2;
      }
      .params {
        width: 100%;
        .param {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 45px;
          .field {
            max-width: 210px;
            flex: 1;
            &.value {
              margin-left: 16px;
              max-width: unset;
            }
          }
          .removeParamButton {
            margin-left: 5px;
            margin-top: 12px;
            color: $light-icon;
            position: absolute;
            right: 20px;
          }
        }
      }
      .addParamButton {
        padding: 20px 8px;
      }
    }
  }
}
