@import "~@river/common-ui/src/styles/colors";

.root {
  position: relative;
  .outlinedInput {
    width: 100%;
    .input {
      padding-right: 55px;
    }
  }
  .passwordVisibilityToggle {
    width: 48px;
    position: absolute;
    right: 3px;
    top: 5px;
    color: rgba(0, 0, 0, 0.6);
  }
}
