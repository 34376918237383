@import "../../styles/colors";

.root {
  .notificationBox {
    min-width: 333px;
    min-height: 102px;
    padding: 17px;
    display: flex;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    background: white;
    box-sizing: border-box;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    position: relative;
    .icon {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: 16px;
      .title {
        font-family: "IBM Plex Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 120%;
        display: flex;
        align-items: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
      }
      .message {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: 0.25px;
        color: $light-theme-text-2;
        margin-top: 4px;
      }
    }
    &.success {
      border-color: $green;
      .title {
        color: $green;
      }
    }
    &.info {
      border-color: $blue;
      .title {
        color: $blue;
      }
    }
    &.warn {
      border-color: $orange;
      .title {
        color: $orange;
      }
    }
    &.error {
      border-color: $red;
      .title,
      .message {
        color: $red;
      }
    }
    .closeIcon {
      position: absolute;
      top: 5px;
      right: 5px;
      color: $light-icon;
      width: 24px;
      height: 24px;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
