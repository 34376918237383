@import "~@river/common-ui/src/styles/colors";

.sidebar {
  overflow: hidden;
  transition: width 0.2s;
  width: 250px;
  background-color: $sidebar-platform-bg;

  &.collapsed {
    width: 0 !important;
  }

  .list {
    width: 100%;
    padding: 0;
    .parentItemIcon {
      svg {
        width: 7.5px !important;
      }
    }
    .divider {
      margin-top: 9px;
      margin-bottom: 9px;
      background-color: $dark-theme-stroke;
    }
  }

  &.mobile {
    width: 100%;
  }
}
