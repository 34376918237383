@import "../../styles/colors";

.container {
  height: 100vh;
  padding: 0;

  .body {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
      min-height: 46px;
      background-color: $blue;
    }

    .footer {
      min-height: 46px;
      background-color: $sidebar-color;
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 60px;
      .title {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 0.25px;
        color: #252b3a;
        text-align: center;
      }
      .subtitle {
        margin-top: 20px;
        font-family: "IBM Plex Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.6);
        text-align: center;
      }
      form {
        margin-top: 72px;
        display: flex;
        flex-direction: column;
        .password {
          margin-bottom: 16px;
        }
        .matchInfo {
          font-family: "Open Sans", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 120%;
          letter-spacing: 0.5px;
          color: rgba(0, 0, 0, 0.6);
          margin-top: 5px;
          .error {
            color: $red;
          }
        }
        .loginButton {
          max-width: 154px;
          width: 100%;
          height: 36px;
          margin-top: 37px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
      .passwordUpdatedContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .goToAccount {
          margin-top: 56px;
          font-family: "IBM Plex Sans", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 120%;
          text-align: center;
          letter-spacing: 1.25px;
          color: $light-theme-text-1;
        }
      }
    }
  }
}
