@import "../../../styles/colors";

.root {
  width: 52px;
  height: 32px;
  padding: 0;

  & > :global(.MuiSwitch-switchBase) {
    padding: 0;
    margin: 8px 0 0 8px;
    transition-duration: 300ms;

    &:global(.Mui-checked) {
      margin: 4px 0 0 8px;
      transform: translateX(16px);
      color: white;

      & > :global(.MuiSwitch-thumb) {
        box-sizing: border-box;
        width: 24px;
        height: 24px;
        background-color: white;
      }

      & + :global(.MuiSwitch-track) {
        background-color: $blue2;
        opacity: 1;
        border: solid 2px $blue2;
      }

      &:global(.Mui-disabled) {
        & + :global(.MuiSwitch-track) {
          opacity: 0.5;
        }
      }
    }

    &:global(.Mui-disabled) {
      & > :global(.MuiSwitch-thumb) {
        color: grey;
      }
    }

    &:global(.Mui-disabled) {
      & + :global(.MuiSwitch-track) {
        opacity: 0.7;
      }
    }

    & > :global(.MuiSwitch-thumb) {
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      background-color: $grey7;
    }
  }

  & > :global(.MuiSwitch-track) {
    border-radius: 100px;
    background-color: #f2f2f2;
    opacity: 1;
    transition-duration: 500ms;
    border: solid 2px $grey7;
  }
}

.formControl {
  gap: 18px;
  margin-right: 0;

  .checkBox {
    :global(.MuiSvgIcon-root) {
      font-size: 18px;
    }
  }
  .label {
    color: $light-theme-text-2;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
  }
}
