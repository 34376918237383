.apiKeysGrid {
  .icons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .icon {
      cursor: pointer;
      margin: 7px;
    }
  }
  .apiKeyTextBox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
