.root {
  font-family: "IBM Plex Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 120% !important;
  letter-spacing: 1.25px !important;
  padding: 8px 16px !important;
  color: white !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: fit-content;
}
