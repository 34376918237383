@import "~@river/common-ui/src/styles/colors";

.root {
  display: flex;
  flex-direction: column;
  width: fit-content;
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    .logo {
      width: 100%;
      height: 100%;
      background-position: center !important;
      background-size: auto !important;
      flex: 1;
    }
    .status {
      width: 100%;
      height: 30px;
      display: flex;
      justify-content: center;
      border-top: 1px solid $dark-theme-stroke;
    }
  }
  .desc {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .name {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 120%;
      text-align: center;
      letter-spacing: 0.5px;
      color: $light-theme-text-1;
    }
  }
  &:hover {
    .card {
      filter: drop-shadow(-4px 4px 8px rgba(0, 0, 0, 0.12));
      cursor: pointer;
    }
    .desc {
      .name {
        text-decoration: underline;
      }
    }
  }
}
