@import "../../styles/colors.scss";

.root {
  width: 100%;
  border-radius: 6px;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px #1018281a;
}

.neutral {
  color: $grey7;
}

.success {
  color: $green2;
}

.error {
  color: $red2;
}

.main {
  position: relative;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.2s;
  min-height: 163px;
}

.main_hide {
  max-height: 0;
  overflow: hidden;
  height: 0;
  min-height: 0;
}

.item {
  font-family: "Open Sans", sans-serif;
  width: 300px;
  padding: 20px;
  height: 155px;
  display: flex;
  flex-direction: column;
}

.item_bottom {
  height: 66px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
}

.indicator {
  background-color: $blue2;
}

.tab_root {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  line-height: 39px;
  height: 39px;
  color: $grey6;
  border-bottom: unset;
  transition: all 0.2s;
  margin-right: 20px;
  text-transform: none;

  &:global(.Mui-selected) {
    color: $blue2;
  }
}

.tabs_root {
  width: 100%;
  padding: 0 12px;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
}

.titles_container {
  display: flex;
}

.toggle {
  line-height: 26px;
  color: $grey6;
  right: 12px;
  position: absolute;
  cursor: pointer;
}

.item_label {
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  flex-grow: 1;
  text-align: left;
  color: $grey6;
}

.item_value {
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: left;
  color: $grey6;
}

.item_detail {
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}
