@import "@river/common-ui/src/styles/colors";

.select {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 120% !important;
  letter-spacing: 0.25px !important;
  color: $light-theme-text-2 !important;
  padding: 0 31px 0 6px !important;
  &:focus {
    background-color: transparent !important;
  }
}
.dropdownIcon {
  color: $dropdown-icon !important;
}

.menu {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2) !important;
  .list {
    padding-top: 0;
    padding-bottom: 0;
    .item {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 120%;
      display: flex;
      align-items: center;
      letter-spacing: 0.25px;
      color: $light-theme-text-2 !important;
      padding: 6px 28px 6px 6px;
      &.selected {
        background-color: $blue;
        color: white !important;
      }
    }
  }
}
