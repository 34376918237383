@import "~@river/common-ui/src/styles/colors";

.formatterContainer {
  display: flex;
  justify-content: space-between;
  height: 100%;
  .formatterIcon {
    width: 15px;
    align-self: center;
  }
  .formatterText {
    margin: 0;
    margin-left: 8px;
    padding: 0;
    height: 100%;
  }
}
