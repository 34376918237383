@import "~@river/common-ui/src/styles/colors";

.root {
  width: 100%;
  height: 100vh;
  display: flex;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .primaryButtons {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 15px;
      padding: 0 16px 0 30px;

      .primaryButton {
        font-family: "IBM Plex Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: 1.25px;
        padding: 8px 24px;
      }
    }

    .viewContent {
      width: 100%;
      overflow: auto;
      padding: 0 16px 0 30px;

      .form {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        .input {
          margin-top: 25px;
          .link {
            input {
              cursor: pointer;
              text-decoration: underline;

              color: #4559da;
              background: linear-gradient(
                291.39deg,
                #4559da 0%,
                #29b4d7 98.74%
              );
              background-clip: text;
              -webkit-text-fill-color: transparent;
              &:hover {
                color: #01c6bf;
                background: linear-gradient(
                  291.39deg,
                  #d823ff 0%,
                  #29b4d7 98.74%
                );
                background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }
        }

        .buttonGroup {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: 30px;
          margin-bottom: 20px;
        }
        .button {
          display: flex;
          padding: 14px;
          margin-left: 20px;
          min-width: 115px;
        }

        .formSection {
          display: flex;
          flex-direction: column;
          margin-top: 40px;

          .sectionHeader {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 20px;

            .sectionTitle {
              font-size: 22px;
              font-family: "IBM Plex Sans", sans-serif;
              line-height: 120%;
              letter-spacing: 0.15px;
              color: #263647;
            }
          }
        }
      }

      .dataGrid {
        flex: 1;
        width: 100%;
        margin-top: 20px;
        :global .rdg-header-row,
        :global .rdg-row {
          grid-template-columns: 2.4fr 3.3fr 4.3fr;
        }
        .delete {
          color: rgb(90, 90, 90);
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .dataGridLink {
          cursor: pointer;
          text-decoration: underline;
          color: #4559da;
          background: linear-gradient(291.39deg, #4559da 0%, #29b4d7 98.74%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          &:hover {
            color: #01c6bf;
            background: linear-gradient(291.39deg, #d823ff 0%, #29b4d7 98.74%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .deleteButton {
          :global(.MuiButton-containedSizeMedium) {
            background-color: rgb(255, 85, 85);
          }
          :global(.MuiButton-containedSizeMedium):hover {
            background-color: rgb(255, 102, 102);
          }
        }
      }
    }
  }

  &.mobile {
    .primaryButtons {
      padding-left: 5px;
    }
    .viewContent {
      padding: 8px 0 0 6px;
    }
  }
}
