@import "~@river/common-ui/src/styles/colors";

.addParamsSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid $light-icon;
  border-bottom: 1px solid $light-icon;
  margin-top: 20px;
  margin-bottom: 4px;

  .title {
    padding-top: 20px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.5px;
    color: $light-theme-text-2;
    margin-bottom: 12px;
  }
  .params {
    width: 100%;
    .param {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .field {
        max-width: 210px;
        flex: 1;
        &.value {
          margin-left: 16px;
          max-width: unset;
        }
      }
      .removeParamButton {
        margin-left: 35px;
        color: $light-icon;
        right: 20px;
      }
    }
  }
  .addParamButton {
    padding: 20px 8px;
  }
}
