@import "../../styles/colors";

.select {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 11px !important;
  letter-spacing: 0.5px !important;
  padding: 0 24px 0 3px !important;
  min-height: unset !important;
  color: #6b8199;

  &:focus {
    background: none;
  }

  :global(.MuiSelect-nativeInput) {
    position: absolute;
  }
}

.dropdownIcon {
  font-size: 24px;
  right: 0;
}

.paper {
  position: absolute;

  .list {
    position: relative;

    li {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      color: #6b8199;
      font-size: 12px;
      min-height: auto;
    }
  }
}
