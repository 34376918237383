@import "~@river/common-ui/src/styles/colors";

.anchor {
  width: fit-content;
  .anchorIcon {
    display: flex;
    svg {
      width: 19px;
      height: 19px;
      color: $menu-vertical;
    }
  }
  &:global(.MuiButton-root) {
    .anchorIcon {
      margin-right: 6px;
    }
  }
}
.menu {
  .paper {
    .menuList {
      display: flex;
      padding: 0 !important;
      height: 100%;
      align-items: center;
      .menuItem {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 120%;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        height: 100%;
        min-height: 27px;
        padding: 0 8px;
        color: $gray2;
      }
    }
  }
}
