@import "../../styles/colors";

.paper {
  border-radius: 12px !important;
  width: 536px;
  box-sizing: border-box;
  min-height: 32px;
  box-shadow:
    0 16px 24px rgba(0, 0, 0, 0.14),
    0 6px 30px rgba(0, 0, 0, 0.12),
    0 8px 10px rgba(0, 0, 0, 0.2);
  .dialogTitle {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    height: fit-content;
    align-items: center;
    &.draggable {
      cursor: move;
    }

    .titleContent {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      padding: 13px 0;
      .iconSection {
        border: 1px solid #e4e7ec;
        border-radius: 10px;
        padding: 12px;
        display: flex;
        width: 48px;
        height: 48px;
        align-items: center;
        justify-content: center;

        .icon {
          font-size: 24px;
          width: 1em;
          height: 1em;
        }
      }
      .titleSection {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-family: Lato, sans-serif;
        font-style: normal;
        .title {
          flex: 1;
          font-size: 18px;
          font-weight: 600;
          font-family: Lato, sans-serif;
          font-style: normal;
          line-height: 155.556%;
          color: #333332;
        }
        .subtitle {
          font-size: 14px;
          font-weight: 400;
          line-height: 142.857%;
          color: #475467;
        }
      }
    }

    .closeIconButton {
      display: flex !important;
      position: absolute !important;
      right: 6px;
      font-size: 30px !important;
      cursor: pointer !important;
      svg {
        color: $light-icon2;
      }
    }
  }
  .titleDivider {
    margin-top: 0;
  }
  .dialogContent {
    padding: 0 24px;
    :global .MuiFormControl-root {
      margin-top: 16px;
    }
  }
  .dialogActions {
    padding: 20px 24px;
    .button {
      font-family: "IBM Plex Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: 1.25px;
      padding: 12px 30px;
      text-transform: capitalize;
      font-size: 15px;
      &.dimmed {
        color: $grey6;
        &:global(.MuiButton-outlined) {
          border-color: $grey6;
        }
      }
      &.action {
        &:not(:global(.Mui-disabled)) {
          color: white;
        }
      }
    }
  }
}

.paper.mobile {
  .dialogTitle {
    .closeButton {
      font-size: 28px !important;
    }
  }
}
