@import "../../../styles/colors";

.root {
  .outlinedInput {
    width: 100%;
  }

  // Need global def to also match type=number, since MUI doesn't apply OutlinedInput.root to numeric fields
  :global(.MuiOutlinedInput-root) {
    legend {
      width: auto;
    }
  }

  &.error {
    .helperText {
      color: $red;
    }
  }

  .input_description {
    font-size: 12px;
    color: $gray4;
  }
}
