@import "~@river/common-ui/src/styles/colors";

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  user-select: none;
  background-color: $sidebar-color;
  border-right: 1px solid #f0f0f0;

  .sidebarHeader {
    padding: 30px 13px 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .sidebarToggle {
      color: $grey5;
    }
  }
}

.sidebar.mobile {
  .sidebarHeader {
    padding-top: 20px;
  }
}
