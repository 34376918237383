@import "~@river/common-ui/src/styles/colors";
$avatar-background-color: #f6f6f6;

.root {
  height: fit-content;
  border-bottom: 1px solid $light-theme-stroke;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 16px 16px 16px 30px !important;

  .leftSection {
    display: flex;
    margin-right: 30px;
    margin-left: 0;

    .sidebarToggle {
      display: none;
    }
    .title {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      display: flex;
      align-items: center;
      letter-spacing: 1.5px;
      color: $light-theme-text-1;
      white-space: nowrap;
    }
  }
  .rightSection {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
    min-width: fit-content;
  }
  .searchSection {
    max-width: 485px;
    min-width: fit-content;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-right: 16px;
    flex: 1;
    border: 1px solid $dark-theme-stroke;
    box-sizing: border-box;
    .searchField {
      margin-left: 10px;
    }
  }
  .divider {
    height: 32px;
  }
  .userInfo {
    display: flex;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;
    .avatar {
      width: 30px;
      height: 30px;
      font-family: "IBM Plex Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.15px;
      background-color: $avatar-background-color;
      color: $light-theme-text-2;
    }
    .name {
      margin-left: 16px;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 120%;
      letter-spacing: 0.25px;
      color: $light-theme-text-2;
      white-space: nowrap;
    }
    .arrow {
      width: 24px;
      height: 24px;
      margin-left: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 12px;
        height: 12px;
        color: $light-theme-text-2;
      }
    }
  }

  &.mobile {
    padding: 16px !important;
    .leftSection {
      margin-left: -13px;
      .sidebarToggle {
        display: inline-flex;
      }
    }
  }
}
