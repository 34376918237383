@import "~@river/common-ui/src/styles/colors";

.loginContainer {
  margin: Max(calc((100vh - 720px) / 2), 0px) auto auto auto;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .banner {
    height: 720px;
    background-size: 800px auto;
    background-position: -129px 0;
  }

  .flexWrapper {
    display: flex;
    justify-content: center;
  }

  .formContainer {
    display: flex;
    flex-direction: column;

    .logo {
      width: 100%;
      max-width: 260px;
      margin-bottom: 27px;
      position: relative;
      left: -14px;
    }

    .landingMessage {
      font-family: "IBM Plex Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0.15px;
      color: $light-theme-text-2;
      margin-bottom: 45px;
    }

    .infoContainer {
      position: relative;
      .processInfo {
        position: absolute;
        top: 30px;
        left: 0;
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: 0.5px;
        &.authenticating {
          color: $light-theme-text-2;
        }
        &.error {
          color: $red;
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      .username {
        margin-bottom: 16px;
      }
      .loginLabel {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.5px;
      }
      .authenticationControls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .rememberMe {
          margin-right: 0;
          margin-bottom: 0;
          color: $light-theme-text-2;
          .MuiSvgIcon-fontSizeSmall {
            font-size: 16px;
          }
        }
        .forgotPassword {
          color: $link;
          border-bottom: 1px solid $link;
          margin-right: 20px;
          white-space: nowrap;
          text-decoration: none;
        }
      }
      .mainButtons {
        display: flex;
        justify-content: space-between;
        margin-top: 56px;
        .mainButton {
          max-width: 154px;
          width: 100%;
          height: 36px;
          &.login {
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          }
          &.signup {
            border: 1px solid $dark-icon;
          }
          &.disabled {
            box-shadow: none;
            background-color: #f1f1f1;
            border: 1px solid #c7c7c7;
          }
        }
      }
    }
  }

  .rightGrid {
    min-width: fit-content;
  }

  &.mobile {
    .rightGrid {
      min-width: auto;
    }
  }
}
