.checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border: 2px solid #f0f0f0;
  cursor: pointer;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &.mobile {
    width: 14px;
    height: 14px;
  }
}
.checkbox:checked {
  box-shadow: none;
  border: none;
  background-color: transparent;
  background-image: url("../../../../assets/rdg-checked.svg");
  background-size: contain;
}
