.customerList {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  margin-top: 15px;
  padding-top: 5px !important;

  .customerItem {
    margin-right: 16px;
    margin-bottom: 20px;
  }
}
