@import "../../styles/colors";

.forgotPassword {
  padding: 20px 30px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  max-width: 535px !important;
  color: $light-theme-text-2;
  margin: 10px !important;

  .contentRoot {
    padding: 0;
    &:first-child {
      padding-top: 0;
    }
    .header {
      .headerText {
        font-size: 15px;
        letter-spacing: 0.5px;
        div:nth-child(1) {
          color: $light-theme-text-2;
        }
        div:nth-child(2) {
          color: $light-theme-text-1;
        }
      }
    }
    .email {
      margin-top: 20px;
    }
  }

  .footer {
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .helpInfo {
      font-size: 13px;
      letter-spacing: 0.25px;
      color: $light-theme-text-2;
      .contactSupport {
        margin-left: 3px;
        color: $light-theme-text-1;
      }
    }
    .dialogButtons {
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
    }
  }
}

.confirmationPopup {
  .confirmationCheck {
    font-family: "IBM Plex Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 120%;
    letter-spacing: 0.25px;
    color: $link;
    text-align: center;
  }
  .confirmationSent {
    margin-top: 10px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.6);
  }
  .confirmationSpam {
    margin-top: 10px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.25px;
    color: $light-theme-text-2;
  }

  .popupBottomSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 290px;
    max-width: 290px;
  }

  :global .MuiDialog-paper {
    border-radius: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 26px 116px;
  }

  &.mobile {
    .popupBottomSection {
      max-width: 240px;
    }
    :global .MuiDialog-paper {
      padding: 20px;
    }
  }
}

.button {
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  height: 36px;
  font-size: 13px;
  line-height: 120%;
  letter-spacing: 1.25px;
  padding: 11px 14px;
  &.cancel {
    color: $light-theme-text-2;
    margin-right: 2px;
  }
  &.confirmationSendAgain {
    margin-top: 20px !important;
    width: 100%;
  }
}
