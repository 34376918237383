@import "~@river/common-ui/src/styles/colors";

.root {
  width: 100%;
  border: none;
  :global .rdg-cell {
    outline: none;
    border: none;
    &[aria-selected="true"] {
      box-shadow: none;
    }
  }
  :global .rdg-header-row {
    background-color: $light-background;
    border-bottom: 1px solid $light-theme-stroke;
    .rdg-cell {
      font-family: "IBM Plex Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.1px;
      color: $light-theme-text-2;
    }
  }
  :global .rdg-row {
    border-bottom: 1px solid $light-theme-stroke;
    &:hover {
      background: none;
    }
  }
}
