$red: #eb5757;
$blue: #0071ce;
$blue2: #2843c8;
$gray2: #4f4f4f;
$gray4: #bdbdbd;
$grey5: #454746;
$grey6: #454746;
$grey7: #7e7e7e;
$green: #8ac170;
$green2: #387814;
$orange: #ff8600;
$red2: #d04b3a;
$light-theme-text-1: #333333;
$light-theme-text-2: #828282;
$light-theme-stroke: rgba(0, 0, 0, 0.12);
$dark-theme-stroke: rgba(224, 224, 224, 0.2);
$dark-icon: #4f6484;
$light-icon: #e0e0e0;
$light-icon2: #c9c9c9;
$light-background: white;
$dark-background: #111727;
$sidebar-color: #ffffff;
$selected-sidebar: #202e5c;
$dropdown-icon: #5f6368;
$menu-vertical: #c4c4c4;
$link: #000f9f;
$sidebar-platform-bg: #181b25;
