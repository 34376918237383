.root {
  display: flex;
  width: fit-content;
  .image {
    position: relative;
    width: 23px;
    height: 16px;
    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .text {
    margin-left: 5px;
  }
}
