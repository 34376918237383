.root {
  width: 16px !important;
  height: 16px !important;
  cursor: pointer;
  &.collapsed {
    position: relative;
    left: -7px;
  }
}

.root.mobile {
  width: 23px !important;
  height: 23px !important;
}
