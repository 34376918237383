.formControl {
  width: fit-content;
  padding-left: 11px;
  .checkBox {
    :global(.MuiSvgIcon-root) {
      font-size: 18px;
    }
  }
  .label {
    font-size: 15px;
    font-style: normal;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: normal;
    line-height: 120%;
    letter-spacing: 0.15px;
  }
  &.smallerIcon {
    height: 10px;
  }
}
