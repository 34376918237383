@import "~@river/common-ui/src/styles/colors";

.paper {
  padding: 16px;
  width: 395px;
  background: white;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.14), 0 6px 30px rgba(0, 0, 0, 0.12),
    0 8px 10px rgba(0, 0, 0, 0.2);

  .topSection {
    display: flex;
    .logo {
      justify-content: flex-start;
      display: block;
    }
    .topContent {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 16px;
      .accountInfo {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid $light-theme-stroke;
        .displayName {
          font-family: "IBM Plex Sans", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 120%;
          text-align: center;
          letter-spacing: 0.15px;
          color: $light-theme-text-1;
        }
        .email {
          margin: 8px 0;
          font-family: "Open Sans", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 120%;
          letter-spacing: 0.25px;
          color: $light-theme-text-2;
        }
      }
      .tabs {
        width: 100%;
        margin-bottom: 16px;
        border-bottom: 1px solid $light-theme-stroke;
        .flexContainer {
          justify-content: space-between;
          .tab {
            font-family: "Open Sans", sans-serif !important;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 120%;
            letter-spacing: 0.5px;
            color: $blue;
            text-transform: none;
            min-width: unset;
            padding-left: 0;
            padding-right: 0;
            opacity: 1;
            max-width: fit-content;
          }
        }
        .indicator {
          height: 1px;
        }
      }
      .logout {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.1px;
        color: $blue;
        text-transform: none;
        padding: 4px 5px;
      }
    }
  }

  &.mobile {
    .logo {
      display: none;
    }
    .topContent {
      margin-left: 0;
    }
  }
}
