@import "~@river/common-ui/src/styles/colors";

.root {
  .input {
    height: 40px;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: $light-theme-text-2;
    padding-right: 37px;
    &::placeholder {
      position: absolute;
      top: 11px;
      right: 44px;
      color: $light-theme-text-2;
      opacity: 0.9;
    }
  }
  .outlined {
    border-color: transparent;
    legend {
      width: auto;
    }
  }
  .focused {
    .outlined {
    }
  }
  .searchIcon {
    position: absolute;
    right: -4px;
    top: -3px;
  }
}
