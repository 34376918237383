@import "~@river/common-ui/src/styles/colors";

.closeIcon {
  display: flex !important;
  position: absolute !important;
  top: 5px;
  right: 8px;
  font-size: 20px !important;
  cursor: pointer !important;
  svg {
    color: $light-icon;
  }
}
